import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
import { Card, Button } from "react-bootstrap";
import Wrapper from "../../ui/Wrapper";
import Nav from "../../ui/Nav";
import ConcertStyle from "../Concert/ConcertStyle.module.css";
import AboutStyle from "../../About.module.css";
import UserLink from "../../ui/UserLink";

const SportsGallery = () => {
  const concertWrapper = ConcertStyle.concertWrapper;
  const concertTitle = ConcertStyle.concertTitle;
  const backBtn = ConcertStyle.backBtn;

  const subLink = "/inquire";
  const subLInkInquire = AboutStyle.subLInkInquire;

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      <Nav />
      <Wrapper className={concertWrapper}>
        <Card.Title className={concertTitle}>Sports Gallery</Card.Title>
        <div style={{ display: "flex" }}>
          <Button className={backBtn} href="/">
            HOME
          </Button>
          <UserLink cName={subLInkInquire} linkTo={subLink}>
            BOOK NOW
          </UserLink>
        </div>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Wrapper>
    </>
  );
};
export default SportsGallery;
