import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Form, Card, InputGroup } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import ContactStyle from "../Contact.module.css";
import Wrapper from "../ui/Wrapper";

const ContactValidateTest = () => {
  const contact = ContactStyle.contact;
  const form = ContactStyle.form;
  const name = ContactStyle.name;
  const innerCardWrapper = ContactStyle.innerCardWrapper;
  const btn = ContactStyle.btn;

  const [fullName, setFullName] = useState([]);

  //validate form
  const [validated, setValidated] = useState(false);
  const [test, setTest] = useState(false);

  const navigate = useNavigate();
  const refForm = useRef();

  // check if string is empty
  const checkString = () => {
    // console.log("name", fullName)
    if (fullName.length === 0) {
      console.log("empty");
      setTest(true);
    } else {
      if (fullName.length > 3) {
        console.log(fullName);
        setTest(false);
      }
    }
  };

  const sendMyEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    emailjs
      .sendForm(
        "service_vot1p6c", //service id
        "booking-other", //template id
        // console.log("DATA", refForm.current.name.value)
        refForm.current,
        "KL1A_jO5VMRJh9xEy" //public key
      )
      .then(
        (result) => {
          alert("Message successfully sent!");
          navigate(
            // `/tmp/emilescreations.com/thankyou/${refForm.current.name.value}`
            `thankyou/${refForm.current.name.value}`
          );
          window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  const sendEmail = (e) => {
    checkString();
    // checkedString();

    //fires error messages only - does prevent submit!
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    }
    setValidated(true);
  };

  return (
    <>
      <Wrapper className={innerCardWrapper}>
        <Wrapper>
          <Card.Title className={contact}>Contact test</Card.Title>
          <Form
            id="contact"
            ref={refForm}
            className={form}
            autoComplete="on"
            noValidate
            validated={validated}
            onSubmit={sendMyEmail}
            onChange={sendEmail}
          >
            <Form.Label>Full Name*</Form.Label>
            <Form.Group as={Col}>
              <InputGroup hasValidation>
                <Form.Control
                  className={name}
                  name="name"
                  type="text"
                  required
                  value={fullName}
                  minLength={3}
                  maxLength={20}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Full name required
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            {test ? (
              <Button
                variant="light"
                type="submit"
                value="send"
                className={btn}
              >
                SUBMIT
              </Button>
            ) : (
              <Button
                disabled={true}
                variant="light"
                type="submit"
                value="send"
                className={btn}
              >
                SUBMIT
              </Button>
            )}
          </Form>
        </Wrapper>
      </Wrapper>
    </>
  );
};
export default ContactValidateTest;
