import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import rise from "../../mp3/EMILE - RISE FINAL.mp3";

const Player = () => (
  <AudioPlayer
    src={rise}
    Rewind={false}
    Forward={false}
    loop={false}
    className="rise"
    onPlay={(e) => console.log("onPlay")}
    // other props here
  />
);

export default Player;
