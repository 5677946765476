import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Form, Card, InputGroup } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import ContactStyle from "./Contact.module.css";
import Wrapper from "./ui/Wrapper";

const Contact = () => {
  const contact = ContactStyle.contact;
  const form = ContactStyle.form;
  const email = ContactStyle.email;
  const name = ContactStyle.name;
  const phone = ContactStyle.phone;
  const innerCardWrapper = ContactStyle.innerCardWrapper;
  const footer = ContactStyle.footer;
  const btn = ContactStyle.btn;
  const btnContact = ContactStyle.btnContact;
  const btnContactGoBack = ContactStyle.btnContactGoBack;
  const optionsDiv = ContactStyle.optionsDiv;
  const interestedInTitle = ContactStyle.interestedInTitle;
  const generalInputForm = ContactStyle.generalInputForm;
  const detailsPhoto = ContactStyle.detailsPhoto;
  const subText = ContactStyle.subText;
  const subTitle = ContactStyle.subTitle;
  const h5Style = ContactStyle.h5Style;

  const ClothesInputForm = ContactStyle.ClothesInputForm;
  const multipleText = ContactStyle.multipleText;
  const MusicInputForm = ContactStyle.MusicInputForm;

  const [ifCheckedGeneral, setIfCheckedGeneral] = useState(false);
  const [IfCheckedPhotos, setIfCheckedPhotos] = useState(false);
  const [IfCheckedClothes, setIfCheckedClothes] = useState(false);
  const [IfCheckedMusic, setIfCheckedMusic] = useState(false);
  const [boxCheckedPhotos, setBoxCheckedPhotos] = useState(false);
  const [boxCheckedGeneral, setBoxCheckedGeneral] = useState(false);
  const [boxCheckedClothes, setBoxCheckedClothes] = useState(false);
  const [boxCheckedMusic, setBoxCheckedMusic] = useState(false);

  const [IfClickedPhotoInquire, setIfClickedPhotoInquire] = useState(false);
  const [IfClickedOtherInquire, setIfClickedOtherInquire] = useState(false);
  const [ifClickedGoBack, setIfClickedGoBack] = useState(false);

  const onChangeGeneral = (e) => {
    if (e.target.name === "" && e.target.checked === true)
      setBoxCheckedGeneral(false);
    else {
      setBoxCheckedGeneral(true);
    }
  };

  const onChangeClothing = (e) => {
    if (e.target.name === "" && e.target.checked === true)
      setBoxCheckedClothes(false);
    else {
      setBoxCheckedClothes(true);
    }
  };

  const onChangeMusic = (e) => {
    if (e.target.name === "" && e.target.checked === true)
      setBoxCheckedMusic(false);
    else {
      setBoxCheckedMusic(true);
    }
  };

  // onClick() is to check name and checked status of checkbox, if true, 1.show input and hide other inputs for turnery expression 2. keep current checkbox checked and uncheck other checkboxes while current is checked
  const changeGeneral = (e) => {
    if (e.target.name === "checkedGeneral" && e.target.checked === true) {
      // console.log("target", e.target.name && e.target.checked === true);
      setIfCheckedGeneral(true);

      setIfCheckedPhotos(false);
      setBoxCheckedPhotos(false);
      setIfCheckedClothes(false);
      setBoxCheckedClothes(false);
      setIfCheckedMusic(false);
      setBoxCheckedMusic(false);
    } else {
      setIfCheckedGeneral(false);
    }
  };

  const changePhotographyInquire = () => {
    setIfClickedPhotoInquire(true);
  };

  const changeOtherInquire = (e) => {
    setIfClickedOtherInquire(true);
  };

  const changeGoBack = (e) => {
    setIfClickedGoBack(true);
    setIfClickedOtherInquire(false);
    setIfClickedPhotoInquire(false);
  };

  const changeClothing = (e) => {
    if (e.target.name === "checkedClothing" && e.target.checked === true) {
      // console.log("target", e.target.name && e.target.checked === true);
      setIfCheckedClothes(true);

      setIfCheckedGeneral(false);
      setBoxCheckedGeneral(false);
      setIfCheckedPhotos(false);
      setBoxCheckedPhotos(false);
      setIfCheckedMusic(false);
      setBoxCheckedMusic(false);
    } else {
      setIfCheckedClothes(false);
    }
  };

  const changeMusic = (e) => {
    if (e.target.name === "checkedMusic" && e.target.checked === true) {
      // console.log("target", e.target.name && e.target.checked === true);
      setIfCheckedMusic(true);

      setIfCheckedGeneral(false);
      setBoxCheckedGeneral(false);
      setIfCheckedPhotos(false);
      setBoxCheckedPhotos(false);
      setIfCheckedClothes(false);
      setBoxCheckedClothes(false);
    } else {
      setIfCheckedMusic(false);
    }
  };

  const navigate = useNavigate();
  const refForm = useRef();

  //for validity
  const [ffIsValid, setFfIsValid] = useState(false);
  const [details, setDetails] = useState("");
  const [validated, setValidated] = useState(false);
  const [phoneFormat, setPhoneFormat] = useState([]);

  // email.js to send data to client
  const sendEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    emailjs
      .sendForm(
        "service_vot1p6c", //service id
        "booking-other", //template id
        // console.log("DATA",  refForm.current.name.value),
        refForm.current,
        "KL1A_jO5VMRJh9xEy" //public key
      )
      .then(
        (result) => {
          //   alert("Message successfully sent!");
          navigate(
            // `/tmp/emilescreations.com/thankyou/${refForm.current.name.value}`
            `thankyou/${refForm.current.name.value}`
          );
          // window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  const sendEmailForPhotography = (e) => {
    e.preventDefault();
    e.stopPropagation();
    emailjs
      .sendForm(
        "service_sz1zpyb", //service id
        "booking-photography", //template id
        // console.log("DATA",  refForm.current.name.value),
        refForm.current,
        "VRklI1zpNAwL_Tda_" //public key
      )
      .then(
        (result) => {
          //   alert("Message successfully sent!");
          navigate(
            // `/tmp/emilescreations.com/thankyou/${refForm.current.name.value}`
            `thankyou/${refForm.current.name.value}`
          );
          // window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  // check if string is empty
  const checkString = () => {
    if (details.length > 2) {
      // console.log("empty");
      setFfIsValid(true);
      setValidated(false);
    }
  };

  const checkPhoneFormat = () => {
    if (phoneFormat.length === 10) {
      console.log("good");
      setFfIsValid(true);
      setValidated(false);
    }
  };

  const validateOnChange = (e) => {
    checkPhoneFormat();
    checkString();

    //fires error messages only - doesn't prevent submit!
    const ckName = e.currentTarget;

    // console.log("test", e.currentTarget);
    if (ckName.checkValidity() === false) {
    }
    setValidated(true);
  };
  return (
    <>
      <Wrapper className={innerCardWrapper}>
        <Wrapper>
          <Card.Title className={contact} id="contact">
            Contact
          </Card.Title>

          {IfClickedPhotoInquire && (
            <div className={interestedInTitle} id="contact">
              <h3 className={subTitle}>Photography Contact Form</h3>
              <div className={subText}>
                Our professional photography services in Long Island, New York,
                are tailored to preserve your cherished memories. Whether you're
                looking for breathtaking wedding photos, or stunning family
                portraits, Emile will exceed your expectations in capturing
                your most precious moments with artistry and precision.
              </div>
            </div>
          )}

          {/* shows photography contact when user selects YES */}
          <div
            className={interestedInTitle}
            id="contact"
            style={{
              textAlign: "center",
              display:
                IfClickedPhotoInquire || IfClickedOtherInquire
                  ? "none"
                  : "block",
            }}
          >
            <h5 className={h5Style}>
              Contact us today to discuss your needs, request a quote, or
              schedule a consultation. We're excited to serve you and help you
              express your faith through our diverse range of services.{" "}
            </h5>
            <div className={subText}>
              Select the appropriate button for your inquiry.
            </div>
          </div>

          <div
            style={{
              backgroundColor:
                IfClickedPhotoInquire || IfClickedOtherInquire
                  ? "transparent"
                  : "#ffffff85",
              padding:
                IfClickedPhotoInquire || IfClickedOtherInquire ? "0" : "1rem",
              borderRadius: ".3rem",
            }}
          >
            <Button
              onClick={changePhotographyInquire}
              className={btnContact}
              style={{
                display:
                  IfClickedPhotoInquire || IfClickedOtherInquire
                    ? "none"
                    : "block",
              }}
            >
              photography services
            </Button>

            {!IfClickedPhotoInquire && (
              <Button
                onClick={changeOtherInquire}
                className={btnContact}
                style={{
                  display: IfClickedOtherInquire ? "none" : "block",
                }}
              >
                non-photography services
              </Button>
            )}

            {IfClickedPhotoInquire && (
              <Button
                onClick={changeGoBack}
                className={btnContactGoBack}
                style={{
                  display: IfClickedOtherInquire ? "none" : "block",
                }}
              >
                Go Back
              </Button>
            )}
            <br />
            {IfClickedPhotoInquire && (
              <Form
                ref={refForm}
                className={form}
                autoComplete="on"
                noValidate
                validated={validated}
                onSubmit={sendEmailForPhotography}
                onChange={validateOnChange}
              >
                <Form.Label>Full Name*</Form.Label>
                <Form.Group as={Col} controlId="formGridName">
                  <InputGroup hasValidation>
                    <Form.Control
                      className={name}
                      name="name"
                      type="text"
                      required
                      minLength={3}
                      maxLength={20}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ marginTop: "-.3rem" }}
                    >
                      Full name required
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Label>Email Address*</Form.Label>
                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                  <InputGroup hasValidation>
                    <Form.Control
                      className={email}
                      name="email"
                      type="email"
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ marginTop: "-.3rem" }}
                    >
                      Please enter a valid email format
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Label>Your Phone Number*</Form.Label>
                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                  <InputGroup hasValidation>
                    <Form.Control
                      name="phone-number"
                      type="tel"
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      required
                      className={phone}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ marginTop: "-.3rem" }}
                    >
                      Please enter a valid phone number format
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <br />
                <Form.Label>
                  Please provide details about your event/project
                </Form.Label>

                <Form.Group as={Col}>
                  <InputGroup hasValidation>
                    <Form.Control
                      style={{ marginTop: ".5rem" }}
                      as="textarea"
                      name="photography-message"
                      type="text"
                      rows={3}
                      placeholder="Here your can talk about your desired location, expectations, budget limit"
                      className={detailsPhoto}
                      minLength={10}
                      required
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ marginTop: "-1rem" }}
                    >
                      Please provide more details
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                {ffIsValid && validated ? (
                  <Button variant="light" type="submit" className={btn}>
                    SUBMIT
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="light"
                    type="submit"
                    className={btn}
                  >
                    SUBMIT
                  </Button>
                )}
              </Form>
            )}
          </div>
          {/* shows other contact when user selects NO */}
          {IfClickedOtherInquire && (
            <Form
              id="contact"
              ref={refForm}
              className={form}
              autoComplete="on"
              noValidate
              validated={validated}
              onSubmit={sendEmail}
              onChange={validateOnChange}
            >
              {/* shows photography heading only when user is on photography form */}
              {IfClickedOtherInquire && (
                <div
                  className={interestedInTitle}
                  style={{
                    display: IfClickedPhotoInquire ? "none" : "block",
                  }}
                  id="contact"
                >
                  <h2 className={subTitle}>General Inquiry Form</h2>
                  <div className={subText}>
                    Are you looking to collaborate with Emile? Contact us
                    today to discuss your needs, request a quote, or schedule a
                    consultation.
                  </div>
                </div>
              )}
              {IfClickedOtherInquire && (
                <Button
                  onClick={changeGoBack}
                  className={btnContactGoBack}
                  style={{
                    display: IfClickedPhotoInquire ? "none" : "block",
                  }}
                >
                  Go Back
                </Button>
              )}
              <br />
              <Form.Label>Full Name*</Form.Label>
              <Form.Group as={Col} controlId="formGridName">
                <InputGroup hasValidation>
                  <Form.Control
                    className={name}
                    name="name"
                    type="text"
                    required
                    minLength={3}
                    maxLength={20}
                  />

                  <Form.Control.Feedback type="invalid">
                    Full name required
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Label>Email Address*</Form.Label>
              <Form.Group as={Col} sm={12} controlId="formGridEmail">
                <InputGroup hasValidation>
                  <Form.Control
                    className={email}
                    name="email"
                    type="email"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email format
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Label>Your Phone Number*</Form.Label>
              <Form.Group as={Col} sm={12} controlId="formGridEmail">
                <InputGroup hasValidation>
                  <Form.Control
                    name="phone-number"
                    type="tel"
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    required
                    className={phone}
                    value={phoneFormat}
                    onChange={(e) => setPhoneFormat(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid phone number format
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <p className={interestedInTitle}>
                <br />
                <b>
                  Select your interest in contacting Emile's Creations in the
                  checkboxes below
                </b>
              </p>
              <div className={optionsDiv}>
                <Form.Check
                  name="checkedGeneral"
                  type="checkbox"
                  onClick={changeGeneral}
                  onChange={onChangeGeneral}
                  checked={boxCheckedGeneral}
                  label="&nbsp;Multiple Services&nbsp;"
                  className={multipleText}
                />

                <Form.Check
                  name="checkedClothing"
                  type="checkbox"
                  onClick={changeClothing}
                  onChange={onChangeClothing}
                  checked={boxCheckedClothes}
                  label="&nbsp;Clothing&nbsp;"
                />

                <Form.Check
                  name="checkedMusic"
                  type="checkbox"
                  onClick={changeMusic}
                  onChange={onChangeMusic}
                  checked={boxCheckedMusic}
                  label="&nbsp;Music&nbsp;"
                />

                {ifCheckedGeneral && (
                  <Form.Control
                    style={{ marginTop: ".5rem" }}
                    as="textarea"
                    name="general-message"
                    type="text"
                    rows={3}
                    placeholder="General requests or questions"
                    className={generalInputForm}
                  />
                )}

                {IfCheckedClothes && (
                  <Form.Control
                    style={{ marginTop: ".5rem" }}
                    as="textarea"
                    name="clothing-message"
                    type="text"
                    rows={3}
                    placeholder="Clothing requests or questions"
                    className={ClothesInputForm}
                  />
                )}
                {IfCheckedMusic && (
                  <Form.Control
                    style={{ marginTop: ".5rem" }}
                    as="textarea"
                    name="music-message"
                    type="text"
                    rows={3}
                    placeholder="Music requests or questions"
                    className={MusicInputForm}
                  />
                )}
              </div>
              {ffIsValid ? (
                <Button variant="light" type="submit" className={btn}>
                  SUBMIT
                </Button>
              ) : (
                <Button
                  disabled={true}
                  variant="light"
                  type="submit"
                  className={btn}
                >
                  SUBMIT
                </Button>
              )}
            </Form>
          )}
        </Wrapper>
      </Wrapper>
      <Card.Text className={footer}>
        © 2023 All Rights Reserved{" "}
        <a
          style={{ color: "#b9b6b6" }}
          href="https://designinglory.com"
          target="_blank"
          rel="noreferrer"
        >
          DesigninGlory.com
        </a>
      </Card.Text>
    </>
  );
};
export default Contact;

// const onChangeGoBack = (e) => {
//   if (e.target.name === "" && e.target.checked === true)
//     setBoxCheckedGoBack(false);
//   else {
//     setBoxCheckedGoBack(true);
//   }
// };

// const changeGoBack = (e) => {
//   if (e.target.name === "checkedGoBack" && e.target.checked === true) {
//     // console.log("target", e.target.name && e.target.checked === true);
//     setifclickedGoBack(true);

//     setIfClickedOtherInquire(false);
//     setBoxCheckedOtherInquire(false);
//     setIfClickedPhotoInquire(false);
//     setBoxCheckedPhotoInquire(false);
//   } else {
//     setifclickedGoBack(false);
//   }
// };

// {IfClickedOtherInquire && (
//   <Form.Check
//     name="checkedGoBack"
//     type="checkbox"
//     onClick={changeGoBack}
//     onChange={onChangeGoBack}
//     checked={boxCheckedGoBack}
//     label="<-- Go Back&nbsp;"
//     className={multipleText}
//     style={{
//       display: IfClickedPhotoInquire ? "none" : "block",
//     }}
//   />
// )}
