import React from "react";
import MusicStyle from "./Music.module.css";
import Player from "./Player";
import Player2 from "./Player2";
import Player3 from "./Player3";
import Wrapper from "../ui/Wrapper";
import { Card } from "react-bootstrap";
import MusicLinks from "./MusicLinks";

const Music = () => {
  const cardWrapper = MusicStyle.cardWrapper;
  const innerCardWrapper = MusicStyle.innerCardWrapper;
  const cardBody = MusicStyle.cardBody;
  const musicTitle = MusicStyle.musicTitle;
  const playerWrapper = MusicStyle.playerWrapper;
  const riseTitle = MusicStyle.riseTitle;
  const freshTitle = MusicStyle.freshTitle;
  const watchTitle = MusicStyle.watchTitle;


  return (
    <>
      <div id="music">
        <Wrapper className={cardWrapper}>
          <Wrapper className={innerCardWrapper}>
            <Wrapper className={cardBody}>
              <Card.Title className={musicTitle}>Music</Card.Title>
              <div className={playerWrapper}>
                <Card.Title className={riseTitle}>RISE</Card.Title>
                <Player />
              </div>
              <div>
                <MusicLinks />
              </div>
              <div className={playerWrapper}><hr/>
                <Card.Title className={freshTitle}>Fresh Air</Card.Title>
                <Player2 />
              </div>
              <div className={playerWrapper}>
                <Card.Title className={watchTitle}>Watch</Card.Title>
                <Player3 />
              </div>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </div>
    </>
  );
};
export default Music;
