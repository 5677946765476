import imgA from "../../../images/food/img-food-a.jpg";
import imgB from "../../../images/food/img-food-b.jpg";
import imgC from "../../../images/food/img-food-c.jpg";
import imgD from "../../../images/food/img-food-d.jpg";
import imgE from "../../../images/food/img-food-e.jpg";
import imgF from "../../../images/food/img-food-f.jpg";
import imgG from "../../../images/food/img-food-g.jpg";
import imgH from "../../../images/food/img-food-h.jpg";

export const photos = [
  {
    src: imgC,
    width: 3,
    height: 3,
  },
  {
    src: imgB,
    width: 3,
    height: 3,
  },
  {
    src: imgD,
    width: 3,
    height: 3,
  },
  {
    src: imgF,
    width: 3,
    height: 3,
  },
  {
    src: imgG,
    width: 3,
    height: 3,
  },
  {
    src: imgA,
    width: 3,
    height: 3,
  },
  {
    src: imgH,
    width: 3,
    height: 3,
  },
  {
    src: imgE,
    width: 3,
    height: 3,
  },
];
