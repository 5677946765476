import React from "react";
import "../style.css"
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
// import { NavDropdown } from "react-bootstrap";
import { Container } from "react-bootstrap";
import NavStyle from "./Nav.module.css";

const Navigation = () => {
  const navBar = NavStyle.navBar;

  return (
    <>
      <Navbar className={navBar} expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            <Nav.Link href="/#home">Home</Nav.Link>
              <Nav.Link href="/#about">About</Nav.Link>
              <Nav.Link href="/#photography">Photography</Nav.Link>
              <Nav.Link href="/#music">Music</Nav.Link>
              <Nav.Link href="/#store">Clothes Store</Nav.Link>
              <Nav.Link href="/#contact">Contact</Nav.Link>
              {/* <Nav.Link href="/tmp/emilescreations.com/#home">Home</Nav.Link>
              <Nav.Link href="/tmp/emilescreations.com/#about">About</Nav.Link>
              <Nav.Link href="/tmp/emilescreations.com/#photography">Photography</Nav.Link>
              <Nav.Link href="/tmp/emilescreations.com/#music">Music</Nav.Link>
              <Nav.Link href="/tmp/emilescreations.com/#store">Clothes Store</Nav.Link>
              <Nav.Link href="/tmp/emilescreations.com/#contact">Contact</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navigation;
