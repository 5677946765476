import imgA from "../../../images/maternity/img-maternity-a.jpg";
import imgB from "../../../images/maternity/img-maternity-b.jpg";
import imgC from "../../../images/maternity/img-maternity-c.jpg";
import imgD from "../../../images/maternity/img-maternity-d.jpg";
import imgE from "../../../images/maternity/img-maternity-e.jpg";
import imgF from "../../../images/maternity/img-maternity-f.jpg";
import imgG from "../../../images/maternity/img-maternity-g.jpg";
import imgH from "../../../images/maternity/img-maternity-h.jpg";
import imgI from "../../../images/maternity/img-maternity-i.jpg";


export const photos = [
  {
    src: imgC,
    width: 3,
    height: 5,
  },
  {
    src: imgB,
    width: 3.5,
    height: 5,
  },
  {
    src: imgE,
    width: 7.5,
    height: 6,
  },
  {
    src: imgF,
    width: 4,
    height: 6,
  },
  {
    src: imgH,
    width: 7,
    height: 5,
  },
  {
    src: imgG,
    width: 3.2,
    height: 6,
  },
  {
    src: imgA,
    width: 4,
    height: 6,
  },
  {
    src: imgD,
    width: 6,
    height: 5,
  },
  {
    src: imgI,
    width: 3.3,
    height: 5,
  },

];
