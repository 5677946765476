import React from "react";
import ClothesStyle from "./Clothes.module.css";
import { Card, Button } from "react-bootstrap";
import Header from "./Header.module.css";

const Clothes = () => {
  const cardWrapper = ClothesStyle.cardWrapper;
  const innerCardWrapper = ClothesStyle.innerCardWrapper;
  const cardBody = ClothesStyle.cardBody;
  const clothesTitle = ClothesStyle.clothesTitle;
  const outerWrapper = ClothesStyle.outerWrapper;
  const animation = ClothesStyle.animation;
  const btnBookNow = Header.btnBookNow;

  return (
    <>
      <div id="store">
        <Card className={cardWrapper}>
          <Card className={outerWrapper}>
            <Card className={innerCardWrapper}>
              <Card.Body className={cardBody}>
                <Card.Title className={clothesTitle}>
                  Apparel
                </Card.Title>
                <Button
                  href="https://www.emilescreations.net/"
                  target="blank"
                  className={btnBookNow}
                >
                  SHOP CLOTHES STORE
                </Button>

                <div className={animation}></div>
              </Card.Body>
            </Card>
          </Card>
        </Card>
      </div>
    </>
  );
};
export default Clothes;
