import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import SuccessStyle from "./Success.module.css";
import Wrapper from "./ui/Wrapper";

const Success = () => {
  const { name } = useParams();
  //   console.log(name);
  const cardWrapper = SuccessStyle.cardWrapper;
  const innerCardWrapper = SuccessStyle.innerCardWrapper;
  const cardBody = SuccessStyle.cardBody;

  return (
    <>
      <Wrapper className="contactBody"></Wrapper>

      <Wrapper className={cardWrapper}>
        <Wrapper className={innerCardWrapper}>
          <Wrapper className={cardBody}>
            <h1>
              Thank you <b>{name}</b></h1> your submission has been received.
             <br />
            <Button style={{marginTop: "1rem"}} variant={"dark"} href="/">
              Back to Website
            </Button>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </>
  );
};
export default Success;
