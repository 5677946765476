import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import watch from "../../mp3/EMILE- WATCHFM1.mp3";

const Player2 = () => (
  <AudioPlayer
    // autoPlay
    src={watch}
    onPlay={(e) => console.log("onPlay")}
    className="watch"
    // other props here
  />
);

export default Player2;
