import imgA from "../../../images/celebrate/img-celebrate-a.jpg";
import imgB from "../../../images/celebrate/img-celebrate-b.jpg";
import imgC from "../../../images/celebrate/img-celebrate-c.jpg";
import imgD from "../../../images/celebrate/img-celebrate-d.jpg";
import imgE from "../../../images/celebrate/img-celebrate-e.jpg";
import imgF from "../../../images/celebrate/img-celebrate-f.jpg";
import imgG from "../../../images/celebrate/img-celebrate-g.jpg";
import imgH from "../../../images/celebrate/img-celebrate-h.jpg";
import imgI from "../../../images/celebrate/img-celebrate-i.jpg";
import imgJ from "../../../images/celebrate/img-celebrate-j.jpg";
import imgK from "../../../images/celebrate/img-celebrate-k.jpg";
import imgL from "../../../images/celebrate/img-celebrate-l.jpg";
import imgM from "../../../images/celebrate/img-celebrate-m.jpg";
import imgN from "../../../images/celebrate/img-celebrate-n.jpg";
import imgO from "../../../images/celebrate/img-celebrate-o.jpg";
import imgP from "../../../images/celebrate/img-celebrate-p.jpg";
import imgQ from "../../../images/celebrate/img-celebrate-q.jpg";
import imgR from "../../../images/celebrate/img-celebrate-r.jpg";
import imgS from "../../../images/celebrate/img-celebrate-s.jpg";




export const photos = [

  {
    src: imgC,
    width: 4,
    height: 6,
  },
  {
    src: imgB,
    width: 3.4,
    height: 5,
  },
  {
    src: imgD,
    width: 2.5,
    height: 4,
  },
  {
    src: imgF,
    width: 4,
    height: 5,
  },
  {
    src: imgG,
    width: 3.5,
    height: 5,
  },
  {
    src: imgI,
    width: 5.5,
    height: 5,
  },
  {
    src: imgA,
    width: 6,
    height: 4,
  },
  {
    src: imgH,
    width: 3,
    height: 3,
  },
  {
    src: imgE,
    width: 6,
    height: 4,
  },
  {
    src: imgK,
    width: 3.5,
    height: 5,
  },
  {
    src: imgO,
    width: 5,
    height: 4,
  },
  {
    src: imgJ,
    width: 5.5,
    height: 4,
  },
  {
    src: imgN,
    width: 3.5,
    height: 5,
  },
  {
    src: imgL,
    width: 3,
    height: 5,
  },
  {
    src: imgM,
    width: 4,
    height: 3.5,
  },
  {
    src: imgP,
    width: 3.5,
    height: 5,
  },
  {
    src: imgQ,
    width: 3,
    height: 5,
  },
  {
    src: imgR,
    width: 3.5,
    height: 5,
  },
  {
    src: imgS,
    width: 3,
    height: 5,
  },

];
