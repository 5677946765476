import imgA from "../../../images/people/img-people-a.jpg";
import imgB from "../../../images/people/img-people-b.jpg";
import imgC from "../../../images/people/img-people-c.jpg";
import imgD from "../../../images/people/img-people-d.jpg";
import imgE from "../../../images/people/img-people-e.jpg";
import imgF from "../../../images/people/img-people-f.jpg";
import imgG from "../../../images/people/img-people-g.jpg";
import imgH from "../../../images/people/img-people-h.jpg";
import imgI from "../../../images/people/img-people-i.jpg";
import imgJ from "../../../images/people/img-people-j.jpg";
import imgK from "../../../images/people/img-people-k.jpg";
import imgL from "../../../images/people/img-people-l.jpg";
import imgM from "../../../images/people/img-people-m.jpg";
import imgN from "../../../images/people/img-people-n.jpg";
import imgO from "../../../images/people/img-people-o.jpg";
import imgP from "../../../images/people/img-people-p.jpg";
import imgQ from "../../../images/people/img-people-q.jpg";
import imgR from "../../../images/people/img-people-r.jpg";
import imgS from "../../../images/people/img-people-s.jpg";
import imgT from "../../../images/people/img-people-t.jpg";

export const photos = [
  {
    src: imgC,
    width: 4,
    height: 6,
  },
  {
    src: imgB,
    width: 3.2,
    height: 5,
  },
  {
    src: imgH,
    width: 4,
    height: 5,
  },
  {
    src: imgD,
    width: 2.5,
    height: 4,
  },
  {
    src: imgF,
    width: 3.5,
    height: 5,
  },
  {
    src: imgG,
    width: 3.5,
    height: 5,
  },
  {
    src: imgE,
    width: 5,
    height: 4.2,
  },
  {
    src: imgP,
    width: 3.5,
    height: 5,
  },
  {
    src: imgI,
    width: 3.4,
    height: 5,
  },
  {
    src: imgA,
    width: 3.4,
    height: 5,
  },

  {
    src: imgO,
    width: 3,
    height: 5,
  },
  {
    src: imgJ,
    width: 3.4,
    height: 5,
  },
  {
    src: imgN,
    width: 3.3,
    height: 5,
  },

  {
    src: imgM,
    width: 3.2,
    height: 5,
  },
  {
    src: imgK,
    width: 2.6,
    height: 5,
  },
  {
    src: imgQ,
    width: 3.5,
    height: 5,
  },
  {
    src: imgR,
    width: 2.5,
    height: 5,
  },
  {
    src: imgL,
    width: 7,
    height: 5,
  },
  {
    src: imgS,
    width: 3.5,
    height: 5,
  },
  {
    src: imgT,
    width: 2.8,
    height: 5,
  },
];
