import React from "react";
import Header from "./Header";
import Nav from "./ui/Nav";
import About from "./About";
import Photography from "./Photography";
import Contact from "./Contact";
import Music from "./Music/Music";
import Clothes from "./Clothes";
import CommingSoon from "./CommingSoon";
import { Container } from "react-bootstrap";

const Screen = () => {
  return (
    <>
      <Container className="p-0">
        <Nav />
        <Header />
        <About />
        <Photography />
        <Music />
        <Clothes />
        <Contact />
        {/* <CommingSoon/> */}
      </Container>
    </>
  );
};
export default Screen;
