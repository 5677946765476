import imgA from "../../../images/church/img-church-a.jpg";
import imgB from "../../../images/church/img-church-b.jpg";
import imgC from "../../../images/church/img-church-c.jpg";
import imgD from "../../../images/church/img-church-d.jpg";
import imgE from "../../../images/church/img-church-e.jpg";
import imgF from "../../../images/church/img-church-f.jpg";
import imgG from "../../../images/church/img-church-g.jpg";
import imgH from "../../../images/church/img-church-h.jpg";
import imgI from "../../../images/church/img-church-i.jpg";
import imgJ from "../../../images/church/img-church-j.jpg";
import imgK from "../../../images/church/img-church-k.jpg";
import imgL from "../../../images/church/img-church-l.jpg";
import imgM from "../../../images/church/img-church-m.jpg";
import imgN from "../../../images/church/img-church-n.jpg";


export const photos = [
  {
    src: imgA,
    width: 4.5,
    height: 4,
  },
  {
    src: imgC,
    width: 3.2,
    height: 5,
  },
  {
    src: imgB,
    width: 2.2,
    height: 4,
  },
  {
    src: imgD,
    width: 7,
    height: 5,
  },
  {
    src: imgE,
    width: 4.5,
    height: 6,
  },
  {
    src: imgF,
    width: 7.5,
    height: 5,
  },
  {
    src: imgK,
    width: 3.5,
    height: 5,
  },
  {
    src: imgG,
    width: 6.5,
    height: 5,
  },
  {
    src: imgI,
    width: 3.2,
    height: 5,
  },
  {
    src: imgH,
    width: 3,
    height: 5,
  },
  {
    src: imgJ,
    width: 3,
    height: 5,
  },
  {
    src: imgN,
    width: 4.8,
    height: 6,
  },
  {
    src: imgL,
    width: 3.5,
    height: 5,
  },
  {
    src: imgM,
    width: 7,
    height: 5,
  },

];
