import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import freshAir from "../../mp3/EMILE - FRESH AIR FM1.mp3";

const Player2 = () => (
  <AudioPlayer
    // autoPlay
    src={freshAir}
    onPlay={(e) => console.log("onPlay")}
    className="fresh-air"
    // other props here
  />
);

export default Player2;
