import imgA from "../../../images/sports/img-sports-a.jpg";
import imgB from "../../../images/sports/img-sports-b.jpg";
import imgC from "../../../images/sports/img-sports-c.jpg";
import imgD from "../../../images/sports/img-sports-d.jpg";
import imgE from "../../../images/sports/img-sports-e.jpg";
import imgF from "../../../images/sports/img-sports-f.jpg";
import imgG from "../../../images/sports/img-sports-g.jpg";
import imgH from "../../../images/sports/img-sports-h.jpg";
import imgI from "../../../images/sports/img-sports-i.jpg";
import imgJ from "../../../images/sports/img-sports-j.jpg";
import imgK from "../../../images/sports/img-sports-k.jpg";
import imgL from "../../../images/sports/img-sports-l.jpg";
import imgM from "../../../images/sports/img-sports-m.jpg";
import imgN from "../../../images/sports/img-sports-n.jpg";
import imgO from "../../../images/sports/img-sports-o.jpg";
import imgP from "../../../images/sports/img-sports-p.jpg";


export const photos = [
  {
    src: imgA,
    width: 4,
    height: 4,
  },
  {
    src: imgC,
    width: 3.2,
    height: 5,
  },
  {
    src: imgN,
    width: 2.5,
    height: 5,
  },
  {
    src: imgB,
    width: 2.5,
    height: 4,
  },
  {
    src: imgD,
    width: 7,
    height: 5,
  },
  {
    src: imgE,
    width: 2.5,
    height: 4,
  },
  {
    src: imgF,
    width: 2.5,
    height: 4,
  },
  {
    src: imgK,
    width: 4,
    height: 4,
  },
  {
    src: imgG,
    width: 6,
    height: 5,
  },
  {
    src: imgI,
    width: 3,
    height: 5,
  },
  {
    src: imgH,
    width: 3.5,
    height: 5,
  },
  {
    src: imgJ,
    width: 3,
    height: 5,
  },
  {
    src: imgM,
    width: 8,
    height: 5,
  },
  {
    src: imgL,
    width: 6.5,
    height: 5,
  },
  {
    src: imgO,
    width: 7.8,
    height: 5,
  },
  {
    src: imgP,
    width: 7.8,
    height: 5,
  },

];
