import React from "react";
import MusicStyle from "./Music.module.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

const MusicLinks = () => {
  const purchaseTitleBtn = MusicStyle.purchaseTitleBtn;
  const musicBtn = MusicStyle.musicBtn;
  const musicPanel = MusicStyle.musicPanel;

  const spotify = "https://open.spotify.com/album/3eQbI0v4jxipV2XYqeAHLV";
  const apple = "https://music.apple.com/us/album/rise-single/1690978942";
  const bandCamp = "https://emilescreations.bandcamp.com/album/rise-single";
  const soundCloud = "https://soundcloud.com/emiles_creations";
  const youTube = "https://www.youtube.com/@EmilesCreations";
  return (
    <>
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton className={purchaseTitleBtn}>
              Click to Purchase or stream RISE today:
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={musicPanel}>
            <a className={musicBtn} href={apple}>
              Purchase on Apple Music
            </a>
          </AccordionItemPanel>
          <AccordionItemPanel className={musicPanel}>
            <a className={musicBtn} href={spotify}>
              Purchase on Spotify
            </a>
          </AccordionItemPanel>
          <AccordionItemPanel className={musicPanel}>
            <a className={musicBtn} href={bandCamp}>
              Purchase on BandCamp
            </a>
          </AccordionItemPanel>
          <AccordionItemPanel className={musicPanel}>
            <a className={musicBtn} href={soundCloud}>
              Stream on Sound Cloud
            </a>
          </AccordionItemPanel>
          <AccordionItemPanel className={musicPanel}>
            <a className={musicBtn} href={youTube}>
              Listen on You Tube
            </a>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
export default MusicLinks;

