import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Screen from "./Components/Screen";
import ConcertGallery from "./Components/Galleries/Concert/ConcertGallery";
import CelebrateGallery from "./Components/Galleries/Celebrate/CelebrateGallery";
import FoodGallery from "./Components/Galleries/Food/FoodGallery";
import ChurchGallery from "./Components/Galleries/Church/ChurchGallery";
import SportsGallery from "./Components/Galleries/Sports/SportsGallery";
import MaternityGallery from "./Components/Galleries/Maternity/MaternityGallery";
import PeopleGallery from "./Components/Galleries/People/PeopleGallery";
import Emile from "./Components/Emile/Emile";
import EthanGallery from "./Components/Galleries/Ethan/EthanGallery";
import ViewMorePhotos from "./Components/ViewMorePhotos";
import EmileForm from "./Components/EmileForm";
import Thankyou from "./Components/Thankyou";
import Success from "./Components/Success";
import Contact from "./Components/Contact";
import ContactPhotography from "./Components/ContactPhotography";
import ContactValidateTest from "./Components/Test/ContactValidateTest";

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/tmp/emilescreations.com" element={<Screen/>} />
          <Route path="/tmp/emilescreations.com/concert/" element={<ConcertGallery/>} />
          <Route path="/tmp/emilescreations.com/celebrate/" element={<CelebrateGallery/>} />
          <Route path="/tmp/emilescreations.com/food/" element={<FoodGallery/>} />
          <Route path="/tmp/emilescreations.com/church/" element={<ChurchGallery/>} />
          <Route path="/tmp/emilescreations.com/sports/" element={<SportsGallery/>} />
          <Route path="/tmp/emilescreations.com/maternity/" element={<MaternityGallery/>} />
          <Route path="/tmp/emilescreations.com/people/" element={<PeopleGallery/>} />
          <Route path="/tmp/emilescreations.com/emile/" element={<Emile/>} />
          <Route path="/tmp/emilescreations.com/ethan/" element={<EthanGallery/>} />
          <Route path="/tmp/emilescreations.com/view-more/" element={<ViewMorePhotos/>} />
          <Route path="/tmp/emilescreations.com/form/" element={<EmileForm/>} />
          <Route path="/tmp/emilescreations.com/contact" element={<Contact/>} />
          <Route path="/tmp/emilescreations.com/val-test" element={<ContactValidateTest/>} />
          <Route path="/tmp/emilescreations.com/inquire" element={<ContactPhotography/>} />
          <Route path="/tmp/emilescreations.com/thankyou/:name" element={<Thankyou/>} />
          <Route path="/tmp/emilescreations.com/success/:name" element={<Success/>} />  */}


          <Route path="/" element={<Screen/>} />
          <Route path="/concert" element={<ConcertGallery/>} />
          <Route path="/celebrate" element={<CelebrateGallery/>} />
          <Route path="/food" element={<FoodGallery/>} />
          <Route path="/church" element={<ChurchGallery/>} />
          <Route path="/sports" element={<SportsGallery/>} />
          <Route path="/maternity" element={<MaternityGallery/>} />
          <Route path="/people" element={<PeopleGallery/>} />
          <Route path="/emile" element={<Emile/>} />
          <Route path="/ethan" element={<EthanGallery/>} />
          <Route path="/view-more" element={<ViewMorePhotos/>} />
          <Route path="/form" element={<EmileForm/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/val-test" element={<ContactValidateTest/>} />
          <Route path="/inquire" element={<ContactPhotography/>} />
          <Route path="/thankyou/:name" element={<Thankyou/>} /> 
          <Route path="/success/:name" element={<Success/>} /> 
        </Routes>
      </Router>
    </>
  );
}

export default App;
