import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Button, Form, Card, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select";
import emailjs from "@emailjs/browser";
import ContactStyle from "./Contact.module.css";
import Wrapper from "./ui/Wrapper";
import Nav from "./ui/Nav";

const ContactPhotography = () => {
  const contact = ContactStyle.contact;
  const form = ContactStyle.form;
  const emailPhoto = ContactStyle.emailPhoto;
  const namePhoto = ContactStyle.namePhoto;
  const phoneNumberPhoto = ContactStyle.phoneNumberPhoto;
  const datePickerPhoto = ContactStyle.datePickerPhoto;
  const typePhoto = ContactStyle.typePhoto;
  const detailsPhoto = ContactStyle.detailsPhoto;
  const innerCardWrapper = ContactStyle.innerCardWrapper;
  const footer = ContactStyle.footer;
  const btn = ContactStyle.btn;
  const explainPhoto = ContactStyle.explainPhoto;

  //send email
  const navigate = useNavigate();
  const refForm = useRef();

  //for date-picker
  const [startDate, setStartDate] = useState(null);
  const [startDateDub, setStartDateDub] = useState(null);

  //for drop down
  const [values, setValues] = useState("");
  const options = [
    // { value: 1, label: "" },
    { value: 1, label: "Celebration Event" },
    { value: 2, label: "Celebration Event" },
    { value: 3, label: "Church Event" },
    { value: 4, label: "Food" },
    { value: 5, label: "School Sports" },
    { value: 6, label: "Family" },
    { value: 7, label: "Head Shots" },
    { value: 8, label: "Maternity" },
    { value: 9, label: "Wedding" },
    { value: 10, label: "Other" },
  ];

  //for validity
  const [dateValid, setDateValid] = useState(true);
  const [dateValidDub, setDateValidDub] = useState(true);
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [ffIsValid, setFfIsValid] = useState(false);
  const [details, setDetails] = useState("");
  const [validated, setValidated] = useState(false);

  // for validation
  const checkDateFunction = (e) => {
    if (startDate === null) {
      setDateValid(false);
      setDateValidDub(false);
    } else {
      setDateValid(true);
      setDateValidDub(true);
    }

    if (values.length === 0) {
      setDataIsEmpty(true);
    } else {
      setDataIsEmpty(false);
    }
  };

  const checkDateFunctionBlur = () => {
    if (!startDate === null) {
      setDateValid(false);
    } else {
      setDateValid(true);
    }
  };

  const checkDateFunctionBlurDub = () => {
    if (!startDateDub === null) {
      setDateValidDub(false);
    } else {
      setDateValidDub(true);
    }
  };

  const checkDropDownFunctionBlur = () => {
    if (values.length === 1) {
      console.log("values", values);
      setDataIsEmpty(true);
    } else {
      setDataIsEmpty(false);
    }
  };

  // check if string is empty
  const checkString = () => {

    if (details.length > 2) {
      // console.log("empty");
      setFfIsValid(true);
      setValidated(false);
    }
  };

  // email.js to send data to client
  const sendEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    emailjs
      .sendForm(
        "service_vot1p6c", //service id
        "photo-inqury", //template id
        // console.log("DATA",  refForm.current.name.value),
        refForm.current,
        "KL1A_jO5VMRJh9xEy" //public key
      )
      .then(
        (result) => {
          // alert("Message successfully sent!");
          navigate(
            // `/tmp/emilescreations.com/thankyou/${refForm.current.name.value}`
            `thankyou/${refForm.current.name.value}`
          );
          window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  const validateOnChange = (e) => {
    checkDateFunction();
    checkString();

    //fires error messages only - doesn't prevent submit!
    const ckName = e.currentTarget;

    console.log("test", e.currentTarget);
    if (ckName.checkValidity() === false) {
    }
    setValidated(true);
  };

  return (
    <>
      <Nav />
      <Wrapper className={innerCardWrapper}>
        <Wrapper>
          <Card.Title className={contact}>Contact</Card.Title>
          <Form
            id="contact"
            ref={refForm}
            className={form}
            autoComplete="on"
            noValidate
            validated={validated}
            onSubmit={sendEmail}
            onChange={validateOnChange}
          >
            <Form.Label>Full Name*</Form.Label>
            <Form.Group as={Col} controlId="formGridName">
              <InputGroup hasValidation>
                <Form.Control
                  className={namePhoto}
                  name="name"
                  type="text"
                  required
                  minLength={3}
                  maxLength={20}
                />

                <Form.Control.Feedback
                  type="invalid"
                  style={{ marginTop: "-.2rem" }}
                >
                  Full name required
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Label>Email Address*</Form.Label>
            <Form.Group as={Col} sm={12} controlId="formGridEmail">
              <InputGroup hasValidation>
                <Form.Control
                  className={emailPhoto}
                  name="email"
                  type="email"
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ marginTop: "-.2rem" }}
                >
                  Please enter a valid email format
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Label>Your Phone Number*</Form.Label>
            <Form.Group as={Col} sm={12} controlId="formGridEmail">
              <InputGroup hasValidation>
                <Form.Control
                  name="phone-number"
                  type="tel"
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  required
                  className={phoneNumberPhoto}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ marginTop: "-.2rem" }}
                >
                  Please enter a valid phone number format
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Label>Best day and time to call*</Form.Label>
            <br />
            <div
              style={{
                height: "1.8rem",
                borderBottom: !dateValid ? "1px solid red" : "transparent",
                marginBottom: ".5rem",
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                onFocus={checkDateFunctionBlur}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                isClearable={true}
                placeholderText="MM/DD/YYYY"
                className={datePickerPhoto}
                name="time"
                required
                type="datetime-local"
                min="2023-06-07T00:00"
              />
            </div>
            {!dateValid && (
              <small
                type="invalid"
                style={{
                  color: "#dc3545",
                  top: "-.5rem",
                  position: "relative",
                }}
              >
                Please enter a date and time
              </small>
            )}
            <br />
            <Form.Label>Date of event/project*</Form.Label>
            <br />
            <div
              style={{
                height: "1.85rem",
                borderBottom: !dateValidDub ? "1px solid red" : "transparent",
                marginBottom: ".5rem",
              }}
            >
              <DatePicker
                selected={startDateDub}
                onChange={(date) => setStartDateDub(date)}
                onFocus={checkDateFunctionBlurDub}
                dateFormat="MM/dd/yyyy"
                isClearable={true}
                placeholderText="MM/DD/YYYY"
                className={datePickerPhoto}
                name="date"
                required
              />
            </div>
            {!dateValidDub && (
              <small
                type="invalid"
                style={{
                  color: "#dc3545",
                  top: "-.5rem",
                  position: "relative",
                }}
              >
                Please enter a date
              </small>
            )}
            <br />
            <div
              style={{
                height: "3.78rem",
                borderBottom: dataIsEmpty ? "1px solid red" : "transparent",
                marginBottom: ".5rem",
              }}
            >
              <Form.Label>Type of photography session*</Form.Label>

              <Select
                options={options}
                onChange={(values) => setValues(values)}
                onDropdownClose={checkDropDownFunctionBlur}
                placeholder="Select an option"
                clearable={true}
                className={typePhoto}
                clearOnSelect={true}
                closeOnSelect
                required
                name="type"
              />
            </div>
            {dataIsEmpty && (
              <small
                type="invalid"
                style={{
                  color: "#dc3545",
                  top: "-.5rem",
                  position: "relative",
                }}
              >
                Please make a selection
              </small>
            )}
            <br />
            <Form.Label style={{ marginTop: "1rem" }}>
              Give details about your event/project
            </Form.Label>
            <Form.Group as={Col}>
              <InputGroup hasValidation>
                <Form.Control
                  style={{ marginTop: ".5rem" }}
                  as="textarea"
                  name="photography-message"
                  type="text"
                  rows={3}
                  className={detailsPhoto}
                  minLength={10}
                  required
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ marginTop: "-1rem" }}
                >
                  Please provide more details
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Label>
              Please explain what you like about my photography
            </Form.Label>
            <Form.Control
              style={{ marginTop: ".5rem" }}
              as="textarea"
              name="details"
              type="text"
              rows={3}
              className={explainPhoto}
            />
            {ffIsValid && validated ? (
              <Button variant="light" type="submit" className={btn}>
                SUBMIT
              </Button>
            ) : (
              <Button
                disabled={true}
                variant="light"
                type="submit"
                className={btn}
              >
                SUBMIT
              </Button>
            )}
          </Form>
        </Wrapper>
      </Wrapper>
      <Card.Text className={footer}>
        © 2023 All Rights Reserved{" "}
        <a
          style={{ color: "#b9b6b6" }}
          href="https://designinglory.com"
          target="_blank"
          rel="noreferrer"
        >
          DesigninGlory.com
        </a>
      </Card.Text>
    </>
  );
};
export default ContactPhotography;
