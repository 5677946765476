import React from "react";
import { Card, Accordion } from "react-bootstrap";
import UserLink from "./ui/UserLink";
import downIcon from "../images/expand_circle_down.png";

import PhotoStyle from "./Photos.module.css";
import useMediaQuery from "./UseMediaQuery";

const Photography = () => {
  const cardWrapper = PhotoStyle.cardWrapper;
  const cardBody = PhotoStyle.cardBody;
  const title = PhotoStyle.title;
  const photoBtn = PhotoStyle.photoBtn;
  const photoBtnCelebrate = PhotoStyle.photoBtnCelebrate;
  const photoBtnFood = PhotoStyle.photoBtnFood;
  const photoBtnChurch = PhotoStyle.photoBtnChurch;
  const photoBtnSports = PhotoStyle.photoBtnSports;
  const photoBtnViewMore = PhotoStyle.photoBtnViewMore;
  const h3ConcertWrapper = PhotoStyle.h3ConcertWrapper;
  const h3CelebrateWrapper = PhotoStyle.h3CelebrateWrapper;
  const AccordionBody = PhotoStyle.AccordionBody;

  
  const subLink = "/concert";
  const subLinkCelebrate = "/celebrate";
  const subLinkFood = "/food";
  const subLinkChurch = "/church";
  const subLinkSports = "/sports";
  const subLinkViewMore = "/view-more";
  // const subLink = "/tmp/emilescreations.com/concert";
  // const subLinkCelebrate = "/tmp/emilescreations.com/celebrate";
  // const subLinkFood = "/tmp/emilescreations.com/food";
  // const subLinkChurch = "/tmp/emilescreations.com/church";
  // const subLinkSports = "/tmp/emilescreations.com/sports";
  // const subLinkViewMore = "/tmp/emilescreations.com/view-more";

  const matchesSm = useMediaQuery("(max-width: 765px)");

  return (
    <>
      <div id="photography">
        {matchesSm ? (
          <Card className={cardWrapper}>
            <Card.Title className={title}>Photography</Card.Title>
            <Card.Body className={cardBody}>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <b style={{ color: "white", paddingLeft: "1rem" }}>
                    CLICK IMAGE TO VIEW GALLERIES
                  </b>
                  <img src={downIcon} alt="" width={45} />
                  <Accordion.Header></Accordion.Header>
                  <Accordion.Body className={AccordionBody}>
                    <div>
                      <div className={h3ConcertWrapper}>
                        <h3>Concert Gallery</h3>
                        <UserLink cName={photoBtn} linkTo={subLink}></UserLink>
                      </div>

                      <div className={h3CelebrateWrapper}>
                        <h3>Celebrations Gallery</h3>
                        <UserLink
                          cName={photoBtnCelebrate}
                          linkTo={subLinkCelebrate}
                        ></UserLink>
                      </div>

                      <div className={h3CelebrateWrapper}>
                        <h3>Food Gallery</h3>
                        <UserLink
                          cName={photoBtnFood}
                          linkTo={subLinkFood}
                        ></UserLink>
                      </div>

                      <div className={h3CelebrateWrapper}>
                        <h3>Church Gallery</h3>
                        <UserLink
                          cName={photoBtnChurch}
                          linkTo={subLinkChurch}
                        ></UserLink>
                      </div>

                      <div className={h3CelebrateWrapper}>
                        <h3>Sports Gallery</h3>
                        <UserLink
                          cName={photoBtnSports}
                          linkTo={subLinkSports}
                        ></UserLink>
                      </div>

                      <div className={h3CelebrateWrapper}>
                        <h3>View More Galleries</h3>
                        <UserLink
                          cName={photoBtnViewMore}
                          linkTo={subLinkViewMore}
                        ></UserLink>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        ) : (
          <Card className={cardWrapper}>
            <Card.Title className={title}>Photography</Card.Title>
            <Card.Body className={cardBody}>
              <div className={h3ConcertWrapper}>
                <h3>Concert Gallery</h3>
                <UserLink cName={photoBtn} linkTo={subLink}></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Celebrations Gallery</h3>
                <UserLink
                  cName={photoBtnCelebrate}
                  linkTo={subLinkCelebrate}
                ></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Food Gallery</h3>
                <UserLink cName={photoBtnFood} linkTo={subLinkFood}></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Church Gallery</h3>
                <UserLink
                  cName={photoBtnChurch}
                  linkTo={subLinkChurch}
                ></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Sports Gallery</h3>
                <UserLink
                  cName={photoBtnSports}
                  linkTo={subLinkSports}
                ></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>View More Galleries</h3>
                <UserLink
                  cName={photoBtnViewMore}
                  linkTo={subLinkViewMore}
                ></UserLink>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};
export default Photography;
