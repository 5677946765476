import React from "react";
import { Card, Button } from "react-bootstrap";
import HeaderStyle from "./Header.module.css";
import Wrapper from "./ui/Wrapper";
import Logo from "../images/logo.png";
// import UserLink from "./ui/UserLink";

const Header = () => {
  const cardWrapper = HeaderStyle.cardWrapper;
  const innerCardWrapper = HeaderStyle.innerCardWrapper;
  const cardBody = HeaderStyle.cardBody;
  const introText = HeaderStyle.introText;
  const subText = HeaderStyle.subText;
  const subTextVideo = HeaderStyle.subTextVideo;
  const subTextOffer = HeaderStyle.subTextOffer;
  const subTextAccept = HeaderStyle.subTextAccept;
  const subTextPayments = HeaderStyle.subTextPayments;
  const subTextCall = HeaderStyle.subTextCall;
  const subTextEm = HeaderStyle.subTextEm;
  const btnPhotos = HeaderStyle.btnPhotos;
  const btnMusic = HeaderStyle.btnMusic;
  const btnPrints = HeaderStyle.btnPrints;
  const logoIcon = HeaderStyle.logoIcon;
  const diagonalOne = HeaderStyle.diagonalOne;
  const diagonalTwo = HeaderStyle.diagonalTwo;
  const diagonalThree = HeaderStyle.diagonalThree;
  const diagonalFour = HeaderStyle.diagonalFour;
  const btnWrapper = HeaderStyle.btnWrapper;
  const emile = HeaderStyle.emile;
  const subTextWrapper = HeaderStyle.subTextWrapper;
  const btnBookNow = HeaderStyle.btnBookNow;
  // const btnPricePackages = HeaderStyle.btnPricePackages
  const btnDivWrapper = HeaderStyle.btnDivWrapper;

  return (
    <>
      <Wrapper className={cardWrapper}>
        <Wrapper className={innerCardWrapper}>
          <Card.Body className={cardBody}>
            <Card.Img src={Logo} alt="" className={[logoIcon]} />

            <div className={btnWrapper}>
              <div className={diagonalOne}></div>
              <div className={diagonalTwo}></div>
              <div className={diagonalThree}></div>
              <div className={diagonalFour}></div>
            </div>

            <Button href="/#photography" className={btnPhotos}>
              Photos
            </Button>
            <Button href="/#music" className={btnMusic}>
              Music
            </Button>
            <Button href="/#store" className={btnPrints}>
              Clothing
            </Button>
            <p className={emile}>Emile Arthur-Ricketts</p>

            <div className={subTextWrapper}>
            <p className={introText}>
              Welcome to EMILE'S CREATIONS. Long Island's premier destination for exceptional
              photography services, stylish Christian apparel, and the
              captivating sounds of a Christian hip hop rapper. We specialize in
              capturing your special moments, dressing you in faith-inspired
              fashion, and delivering music that resonates with your soul.
              Explore our website and experience the perfect blend of
              creativity, fashion, and faith.
              </p>
              <p className={subText}>
                <b>Book For Your Event Today:</b>
              </p>
              <p className={subText}>
                <span style={{ color: "#bf7e3b" }}>
                  Event Celebrations, Concerts, Gourmet Food, Church, Sports,
                  Maternity & more
                </span>
              </p>
              <p className={subTextOffer}>
                <b>Now Offering&nbsp;</b>
              </p>
              <p className={subTextVideo}>
                <b>Videography Services</b>
              </p>
              <p className={subTextAccept}>Payments Accepted:</p>
              <p className={subTextPayments}>
                <span style={{ color: "#bf7e3b" }}>
                  Venmo, Cash App, PayPal, Zelle
                </span>
              </p>
              <p className={subTextOffer}>
                <b>Call or Email Emile at:</b>
              </p>
              <p className={subTextCall}>Phone: 631.530.8819</p>
              <p className={subTextEm}>
                <a href="mailto: booking@emilescreations.com">
                  Em: Booking@EmilesCreations.com
                </a>
              </p>
              <div className={btnDivWrapper}>
                <Button href="#contact" className={btnBookNow}>
                  Inquire
                </Button>
                {/* <UserLink linkTo={PriceLink} cName={btnPricePackages}>
                  Inquire
                </UserLink> */}
              </div>
            </div>
          </Card.Body>
        </Wrapper>
      </Wrapper>
    </>
  );
};
export default Header;
