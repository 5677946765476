import React, { useContext, useLayoutEffect } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Card, Accordion } from "react-bootstrap";
import Wrapper from "../ui/Wrapper";
import Nav from "../ui/Nav";
import ProfileStyle from "./ProfileStyle.module.css";
import useMediaQuery from "../UseMediaQuery";

const Emile = () => {
  const profileTitle = ProfileStyle.profileTitle;
  const screen = ProfileStyle.screen;
  const screenBottom = ProfileStyle.screenBottom;
  const profileWrapper = ProfileStyle.profileWrapper;
  const profileContent = ProfileStyle.profileContent;
  const profileExpandedContent = ProfileStyle.profileExpandedContent;
  const readMoreBtn = ProfileStyle.readMoreBtn;
  const riseWrapper = ProfileStyle.riseWrapper;
  const iFrame = ProfileStyle.iFrame;
  const hrStyle = ProfileStyle.hrStyle;
  const accordionBackground = ProfileStyle.accordionBackground;
  const emStyle = ProfileStyle.emStyle;
  const h2 = ProfileStyle.h2;

  const matchesSm = useMediaQuery("(max-width: 599px)");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <button
        type="button"
        style={{ display: isCurrentEventKey ? "none" : "inline" }}
        onClick={decoratedOnClick}
        className={readMoreBtn}
      >
        {children}
      </button>
    );
  };

  return (
    <>
      <Nav />
      <Wrapper>
        <div className={profileWrapper}>
          <div className={screen}></div>

          <Accordion
            defaultActiveKey="0"
            alwaysOpen={false}
            className={accordionBackground}
          >
            <Wrapper>
              {/* shows little content up to mix width 599px, else show max content  */}
              {matchesSm ? (
                <div className={profileContent}>
                  <Card.Title className={profileTitle}>About Emile</Card.Title>
                  <hr className={hrStyle} />
                  <em>
                    Emile W. Arthur-Ricketts is a mastermind in Photography, Rap
                    Music, and Christian Apparel. Born and raised in the
                    familiar boroughs of The Big Apple, Emile first discovered
                    his passion for Rap and Hip-Hop at the age of 14. &nbsp;
                  </em>
                  <ContextAwareToggle eventKey="1">
                    Read More
                  </ContextAwareToggle>
                </div>
              ) : (
                <div className={profileContent}>
                  <Card.Title className={profileTitle}>About Emile</Card.Title>
                  <hr className={hrStyle} />
                  Emile W. Arthur-Ricketts is a mastermind in Photography, Rap
                  Music, and Christian Apparel. Born and raised in the familiar
                  boroughs of The Big Apple, Emile first discovered his passion
                  for Rap and Hip-Hop at the age of 14. Concretely establishing
                  his way into the culture of music, Emile introduced the world
                  to "DiE$eL-E", (Determined Individual Earning $ Every Lyric
                  Experimentally). Surnamed DiE$eL-E, Emile began his journey
                  and established aspirations of exponential heights. &nbsp;
                  <ContextAwareToggle eventKey="1">
                    Read More
                  </ContextAwareToggle>
                </div>
              )}

              <Accordion.Collapse eventKey="1">
                <Wrapper className={profileExpandedContent}>
                  {matchesSm ? (
                    <p>
                      Concretely establishing his way into the culture of music,
                      Emile introduced the world to "DiE$eL-E", (Determined
                      Individual Earning $ Every Lyric Experimentally). Surnamed
                      DiE$eL-E, Emile began his journey and established
                      aspirations of exponential heights.
                    </p>
                  ) : null}

                  <h2 className={h2}>RAP/HIP HOP ARTIST</h2>
                  <p>
                    One of those goals was to perform and open for major artists
                    including DJ Quik, Talib Kweli, M.O.P., Curren$y, Wu-Tang
                    Clan's Inspectah Deck, and many others. After gleaning from
                    these major game players, Emile released his first albums
                    “The Concept Of E.M.I.L.E.".
                  </p>
                  <p>
                    E.M.I.L.E is made up of 30 acronyms, all of which represent
                    the effects of the gospel molding him into an influential
                    father, a motivated speaker, an impactful leader, and so
                    much more. In short, E.M.I.L.E stands for: “Elohim's
                    Miraculous Intervention Left Evidence”.
                  </p>
                  <p>
                    To Emile, New York is the birthplace of Hip-Hop, and where
                    God has directed his steps to start influencing the masses
                    as a Christian Hip-Hop Artist, and when that work is done,
                    the world.
                  </p>
                  <em>
                    “Since my conversion, God has directed me to rap
                    gospel-infused lyrics over boom bap beats, targeting
                    faith-based and secular hip-hop audiences with both secular
                    and gospel genres. In that sphere of influence, I can
                    passionately share my testimony of God's Truth. My mission
                    is to bring my style and expertise, saving souls in the
                    process, and performing alongside the saved and unsaved. I
                    rap with my new identity in Christ Jesus, allowing his light
                    to shine this truth alone. Where I was once unqualified,
                    Jesus now qualifies me, taking the talent He has embedded in
                    me to influence the world.”
                  </em>
                  <br/>
                  <br/>
                  <em className={emStyle}>
                    “We Should Always Know Who We Are In Christ And What Our
                    Purpose Is. Otherwise, The Devil Makes Suggestions."
                  </em>
                  <br />
                  <br />
                  <p>
                    Emile has also become a formidable advocate for mental
                    health. Known as "The Voice Of The Unpopular Opinion." Emile
                    has a strong backbone in determination, welcoming
                    challenges, overcoming them, and fighting for what he
                    believes in. He can and will do ALL things through Christ
                    Jesus who strengthens him.
                  </p>
                  <h2 className={h2}>PHOTOGRAPHY</h2>
                  <p>
                    Wearing many hats, as a father, entrepreneur, influencer,
                    rapper, advocate, and so much more, Emile has also branded
                    himself in Photography and Christian Apparel under Emile's
                    Creations LLC. In the field of photography, Emile intends to
                    capture the perfect moment, especially for Rap and Hip-Hop
                    concert scenes. This is a niche Emile enjoys to the fullest
                    capacity, and once more, his passion is to provide the best
                    service for his clients, giving them memories that will last
                    forever, bringing out the best capture of that person or
                    event.
                  </p>
                  <h2 className={h2}>CHRISTIAN APPAREL</h2>
                  <p>
                    Another goal and third component of Emile's brand is
                    faith-based Fashion apparel. Styled with urban culture and
                    fitting well in the Hip hop community. The goal of the
                    clothing line is to encourage, uplift and teach capturing
                    the Gospel in hindsight. "Speak Life", "God's L.A.W",
                    "Christ Minded", and "Getcha Faith Up” are just some of the
                    catchphrases God purposed in Emile's heart to print.
                  </p>
                  <p>
                    In conclusion, Emile’s Creations LLC is a representation of
                    the emergence of EMILE, where the old has passed away and
                    the new, Emile has come, resurrected in hope and assurance
                    in Christ alone, having one goal to let his light so SHINE.
                  </p>
                </Wrapper>
              </Accordion.Collapse>
            </Wrapper>
          </Accordion>
          <div className={screenBottom}></div>
        </div>
        <div className={riseWrapper}>
          <iframe
            className={iFrame}
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/KsTprpElOTc"
            title="Rise"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </Wrapper>
    </>
  );
};
export default Emile;
