import imgA from "../../../images/ethan/img-ethan-a.jpg";
import imgB from "../../../images/ethan/img-ethan-b.jpg";
import imgC from "../../../images/ethan/img-ethan-c.jpg";
import imgD from "../../../images/ethan/img-ethan-d.jpg";
import imgE from "../../../images/ethan/img-ethan-e.jpg";
import imgF from "../../../images/ethan/img-ethan-f.jpg";
import imgG from "../../../images/ethan/img-ethan-g.jpg";
import imgH from "../../../images/ethan/img-ethan-h.jpg";
import imgI from "../../../images/ethan/img-ethan-i.jpg";
import imgJ from "../../../images/ethan/img-ethan-j.jpg";
import imgK from "../../../images/ethan/img-ethan-k.jpg";
import imgL from "../../../images/ethan/img-ethan-l.jpg";
import imgM from "../../../images/ethan/img-ethan-m.jpg";
import imgN from "../../../images/ethan/img-ethan-n.jpg";

export const photos = [
  {
    src: imgC,
    width: 4,
    height: 6,
  },
  {
    src: imgF,
    width: 4,
    height: 4,
  },
  {
    src: imgG,
    width: 6.5,
    height: 5,
  },
  {
    src: imgD,
    width: 4,
    height: 4,
  },
  {
    src: imgI,
    width: 5.5,
    height: 7,
  },
  {
    src: imgB,
    width: 4,
    height: 9,
  },
  {
    src: imgA,
    width: 5.5,
    height: 5,
  },
  {
    src: imgH,
    width: 3,
    height: 3,
  },
  {
    src: imgJ,
    width: 5,
    height: 5,
  },
  {
    src: imgE,
    width: 5.5,
    height: 7,
  },
  {
    src: imgK,
    width: 5,
    height: 5,
  },
  {
    src: imgN,
    width: 6,
    height: 7,
  },
  {
    src: imgM,
    width: 5.5,
    height: 7,
  },
  {
    src: imgL,
    width: 5,
    height: 7,
  },
];
