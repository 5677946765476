import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Button, InputGroup } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import FromStyle from "./FormStyle.module.css";
import Logo from "../images/logo.png";
import Wrapper from "./ui/Wrapper";
import ContactStyle from "./Contact.module.css";
import { Link } from "react-router-dom";

const EmileForm = () => {
  const datePickerPhoto = FromStyle.datePickerPhoto;
  const detailsPhoto = ContactStyle.detailsPhoto;
  const footer = ContactStyle.footer;
  const btn = ContactStyle.btn;
  const textarea = FromStyle.textarea;
  const thankYou = FromStyle.thankYou;
  const wrapperStyle = FromStyle.wrapperStyle;
  const subText = FromStyle.subText;
  const label = FromStyle.label;
  const radio = FromStyle.radio;
  const logoStyle = FromStyle.logoStyle;
  const intro = FromStyle.intro;
  const formFiledStyle = FromStyle.formFiledStyle;

  const navigate = useNavigate();
  const refForm = useRef();
  const [checked, setChecked] = useState(false);
  const [digital, setDigital] = useState("");
  const [noDigital, setNoDigitalNo] = useState("");
  const [undecidedForDigital, setUndecidedForDigital] = useState("");
  const [prints, setPrints] = useState("");
  const [noPrints, setNoPrints] = useState("");
  const [undecidedForPrints, setUndecidedForPrints] = useState("");
  const [bw, setBw] = useState("");
  const [noBw, setNoBw] = useState("");
  const [undecidedForBw, setUndecidedForBw] = useState("");

  //for date-picker
  const [startDate, setStartDate] = useState(null);
  const [startDateDub, setStartDateDub] = useState(null);

  //for drop down
  const [values, setValues] = useState("");
  const options = [
    // { value: 1, label: "" },
    { value: 1, label: "Celebration Event" },
    { value: 2, label: "Celebration Event" },
    { value: 3, label: "Church Event" },
    { value: 4, label: "Food" },
    { value: 5, label: "School Sports" },
    { value: 6, label: "Family" },
    { value: 7, label: "Head Shots" },
    { value: 8, label: "Maternity" },
    { value: 9, label: "Wedding" },
    { value: 10, label: "Other" },
  ];

  //for validity
  const [validated, setValidated] = useState(false);
  const [dateValid, setDateValid] = useState(true);
  const [dateValidDub, setDateValidDub] = useState(true);
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [ffIsValid, setFfIsValid] = useState(false);
  const [details, setDetails] = useState("");

  const checkDateFunction = (e) => {
    if (startDate === null) {
      // console.log("false", startDate);
      setDateValid(false);
      setDateValidDub(false);
    } else {
      // console.log("true", startDate);
      setDateValid(true);
      setDateValidDub(true);
    }

    if (values.length === 0) {
      // console.log("empty", values);
      setDataIsEmpty(true);
    } else {
      setDataIsEmpty(false);
    }
  };

  const checkDateFunctionBlur = () => {
    if (!startDate === null) {
      // console.log("falseBlur", startDate);
      setDateValid(false);
    } else {
      // console.log("trueBlur", startDate);
      setDateValid(true);
    }
  };

  const checkDateFunctionBlurDub = () => {
    if (!startDateDub === null) {
      // console.log("falseBlur", startDate);
      setDateValidDub(false);
    } else {
      // console.log("trueBlur", startDate);
      setDateValidDub(true);
    }
  };

  const checkDropDownFunctionBlur = () => {
    if (values === "food") {
      console.log("values", values);
      setDataIsEmpty(true);
    } else {
      // console.log("trueBlur", startDate);
      setDataIsEmpty(false);
    }
  };

  // check if string is empty
  const checkString = () => {
    console.log("name");
    if (details.length > 2) {
      // console.log("empty");
      setFfIsValid(true);
      setValidated(false);
    }
  };

  // email.js to send data to client
  const sendEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    emailjs
      .sendForm(
        "service_sz1zpyb", //service id
        "reply", //template id
        // console.log("DATA",  refForm.current.name.value),
        refForm.current,
        "VRklI1zpNAwL_Tda_" //public key
      )
      .then(
        (result) => {
            alert(`Message successfully sent! Please close this window`);
          navigate(
            // <Link to={`thankyou/${refForm.current.name.value}`}>Go back home</Link>
          //   `/tmp/emilescreations.com/success/${refForm.current.name.value}`
            `thankyou/${refForm.current.name.value}`
          );
          // window.location.reload(false);
          console.log("result", result);
        },
        (error) => {
          alert("Failed to send the message, please try again");
          console.log(error.text);
        }
      );
  };

  const validateOnChange = (e) => {
    checkString();
    checkDateFunction();

    //fires error messages only - does prevent submit!
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    }
    setValidated(true);
  };

  return (
    <>
      <Wrapper className={wrapperStyle}>
        <img alt="Logo" height="auto" src={Logo} className={logoStyle} />
        <h1 className={thankYou}>Thank You for contacting Emile's Creations</h1>
        <div className={intro}>
          <p className={subText}>
            My passion is to give you the best experience by bringing out every
            memory with poise and excellence. To achieve this I kindly ask you
            to complete the form below.
          </p>
        </div>

        <Form
          id="contact-form"
          ref={refForm}
          className={formFiledStyle}
          checked={checked}
          autoComplete="on"
          noValidate
          validated={validated}
          onSubmit={sendEmail}
          onChange={validateOnChange}
        >
          <Form.Label className={label}>Full Name*</Form.Label>
          <Form.Group as={Col}>
            <InputGroup hasValidation>
              <Form.Control
                id="name"
                className={textarea}
                name="name"
                type="text"
                required
                minLength={3}
                maxLength={20}
              />
              <Form.Control.Feedback
                style={{ marginTop: "-.9rem" }}
                type="invalid"
              >
                Full name required
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Label className={label}>Email Address*</Form.Label>
          <Form.Group as={Col} sm={12} controlId="formGridEmail">
            <InputGroup hasValidation>
              <Form.Control
                className={textarea}
                name="email"
                type="email"
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ marginTop: "-.9rem" }}
              >
                Please enter a valid email format
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Label className={label}>Your Phone Number*</Form.Label>
          <Form.Group as={Col} sm={12}>
            <InputGroup hasValidation>
              <Form.Control
                name="phone-number"
                type="tel"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                required
                className={textarea}
              />
              <Form.Control.Feedback
                style={{ marginTop: "-.9rem" }}
                type="invalid"
              >
                Please enter a valid phone number format
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Label className={label}>Best day and time to call*</Form.Label>

          <div
            style={{
              height: "1.8rem",
              // borderBottom: !dateValid ? "1px solid red" : "transparent",
              marginBottom: ".5rem",
            }}
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              onFocus={checkDateFunctionBlur}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              isClearable={true}
              placeholderText="MM/DD/YYYY"
              className={datePickerPhoto}
              name="time"
              required
              type="datetime-local"
              min="2023-06-07T00:00"
            />
          </div>
          {!dateValid && (
            <small
              type="invalid"
              style={{ color: "#dc3545", position: "relative" }}
            >
              Please enter date and time
            </small>
          )}
          <br />
          <Form.Label className={label}>Date of event/project*</Form.Label>

          <div
            style={{
              height: "1.85rem",
              // borderBottom: !dateValidDub ? "1px solid red" : "transparent",
              marginBottom: ".5rem",
            }}
          >
            <DatePicker
              selected={startDateDub}
              onChange={(date) => setStartDateDub(date)}
              onFocus={checkDateFunctionBlurDub}
              dateFormat="MM/dd/yyyy"
              isClearable={true}
              placeholderText="MM/DD/YYYY"
              className={datePickerPhoto}
              name="date"
              required
            />
          </div>
          {!dateValidDub && (
            <small
              type="invalid"
              style={{ color: "#dc3545", position: "relative" }}
            >
              Please enter date
            </small>
          )}

          <div
            style={{
              height: "3.78rem",
              borderBottom: dataIsEmpty ? "1px solid red" : "transparent",
              marginBottom: ".5rem",
            }}
          >
            <Form.Label className={label}>
              Type of photography session*
            </Form.Label>
            <Select
              options={options}
              onChange={(values) => setValues(values)}
              onDropdownClose={checkDropDownFunctionBlur}
              placeholder="Select an option"
              clearable={true}
              className={textarea}
              clearOnSelect={true}
              closeOnSelect
              required
              name="type"
            />
          </div>
          {dataIsEmpty && (
            <small
              type="invalid"
              style={{ color: "#dc3545", position: "relative", top: "0.3rem" }}
            >
              Please make a selection
            </small>
          )}

          <br />
          <Form.Label style={{ marginTop: "1rem" }}>
            Give details about your event/project
          </Form.Label>
          <Form.Group as={Col}>
            <InputGroup hasValidation>
              <Form.Control
                style={{ marginTop: ".5rem" }}
                as="textarea"
                name="photography-message"
                type="text"
                rows={3}
                className={detailsPhoto}
                minLength={10}
                required
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ marginTop: "-1rem" }}
              >
                Please provide more details
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Label>
            Please explain what you like about my photography
          </Form.Label>
          <Form.Control
            style={{ marginTop: "-0.4rem" }}
            as="textarea"
            name="details"
            type="text"
            rows={3}
            className={textarea}
          />

          <Form.Label className={label}>
            What is the emotion, feeling, or vibe you want to portray in these
            images?
          </Form.Label>

          <Form.Control
            id="emotion"
            name="emotion"
            as="textarea"
            style={{ marginTop: "-0.4rem" }}
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>
            What do you want to stay away from?
            <br />
            Are there any cliches in your industry or things you are sick of
            seeing?
          </Form.Label>

          <Form.Control
            id="stay-away"
            name="stay-away"
            as="textarea"
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>
            What is your current demographic? What type of demographic are you
            trying to attract?
          </Form.Label>

          <Form.Control
            id="current-demo"
            name="current-demo"
            as="textarea"
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>
            Are there any "Must-Haves" for the shoot that you require?
          </Form.Label>

          <Form.Control
            id="must-have"
            name="must-have"
            as="textarea"
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>
            How can I bring your vision to life in this photo shoot?
          </Form.Label>

          <Form.Control
            id="vision"
            name="vision"
            as="textarea"
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>
            How many images do you need?
          </Form.Label>

          <div>
            <Form.Control
              id="image-count"
              name="image-count"
              as="textarea"
              type="number"
              placeholder="Your answer here"
            ></Form.Control>
          </div>

          <Form.Label className={label}>My soft deadline is:</Form.Label>

          <Form.Control
            id="soft-deadline"
            name="soft-deadline"
            as="textarea"
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>My hard deadline is:</Form.Label>

          <Form.Control
            id="hard-deadline"
            name="hard-deadline"
            as="textarea"
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>
            Finial thoughts or comments?
          </Form.Label>

          <Form.Control
            id="finial-comments"
            name="finial-comments"
            as="textarea"
            placeholder="Your answer here"
          ></Form.Control>

          <Form.Label className={label}>
            Do you want digital pictures?
          </Form.Label>

          <div className={radio}>
            <Form.Check
              id="Yes I want digital pictures"
              name="Yes I want digital pictures"
              value={digital}
              onClick={() => setDigital("Yes I want digital pictures")}
              label="Yes"
              feedback="Please select yes or no"
              feedbackType="invalid"
            />
            <Form.Check
              id="No I don't want digital pictures"
              name="No I don't want digital pictures"
              value={noDigital}
              onClick={() => setNoDigitalNo("No I don't want digital pictures")}
              label="No"
              feedback="Please select yes or no"
              feedbackType="invalid"
            />
            <Form.Check
              id="I am undecided"
              name="I am undecided"
              value={undecidedForDigital}
              onClick={() => setUndecidedForDigital("I am undecided")}
              label="Undecided"
              feedback="Please select one"
              feedbackType="invalid"
            />
          </div>

          <Form.Label className={label}>Do you want prints?</Form.Label>
          <div className={radio}>
            <Form.Check
              id="Yes I want prints"
              name="Yes I want prints"
              value={prints}
              onClick={() => setPrints("Yes I want prints")}
              label="Yes"
              feedback="Please select yes or no"
              feedbackType="invalid"
            />
            <Form.Check
              id="No I don't want prints"
              name="No I don't want prints"
              value={noPrints}
              onClick={() => setNoPrints("No I don't want prints")}
              label="No"
              feedback="Please select yes or no"
              feedbackType="invalid"
            />
            <Form.Check
              id="I am undecided about prints"
              name="I am undecided about prints"
              value={undecidedForPrints}
              onClick={() =>
                setUndecidedForPrints("I am undecided about prints")
              }
              label="Undecided"
              feedback="Please select one"
              feedbackType="invalid"
            />
          </div>

          <Form.Label className={label}>
            Do you want black-and-white pictures?
          </Form.Label>

          <div className={radio}>
            <Form.Check
              id="Yes I want black and white"
              name="Yes I want black and white"
              value={bw}
              onClick={() => setBw("Yes I want black and white")}
              label="Yes"
              feedback="Please select yes or no"
              feedbackType="invalid"
            />
            <Form.Check
              id="No I don't want black and white"
              name="No I don't want black and white"
              value={noBw}
              onClick={() => setNoBw("No I don't want black and white")}
              label="No"
              feedback="Please select yes or no"
              feedbackType="invalid"
            />
            <Form.Check
              id="I am undecided about black and white pictures"
              name="I am undecided about black and white pictures"
              value={undecidedForBw}
              onClick={() =>
                setUndecidedForBw(
                  "I am undecided about black and white pictures"
                )
              }
              label="Undecided"
              feedback="Please select one"
              feedbackType="invalid"
            />
          </div>

          <div className={footer}></div>

          {ffIsValid && validated ? (
            <Button variant="light" type="submit" className={btn}>
              SUBMIT
            </Button>
          ) : (
            <Button
              disabled={true}
              variant="light"
              type="submit"
              className={btn}
            >
              SUBMIT
            </Button>
          )}
        </Form>
      </Wrapper>
    </>
  );
};
export default EmileForm;
