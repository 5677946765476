import React from "react";
import { Card, Image } from "react-bootstrap";
import Wrapper from "./ui/Wrapper";
import HeadShot from "../images/headshot.png";
import AboutStyle from "./About.module.css";
import UserLink from "./ui/UserLink";

const About = () => {
  const cardWrapper = AboutStyle.cardWrapper;
  const innerCardWrapper = AboutStyle.innerCardWrapper;
  const cardBody = AboutStyle.cardBody;
  const emileImg = AboutStyle.emileImg;
  const aboutEmileSm = AboutStyle.aboutEmileSm;
  const aboutEmileLg = AboutStyle.aboutEmileLg;
  const subText = AboutStyle.subText;
  // const subLink = "/emile";
  const subLink = "/emile";
  const subLinkClass = AboutStyle.subLinkClass;

  return (
    <>
      <div id="about">
        <Wrapper className={cardWrapper}>
          <Wrapper className={innerCardWrapper}>
            <p className={aboutEmileSm}>About Emile</p>
            <Card.Body className={cardBody}>
              <Image src={HeadShot} className={emileImg} />

              <div className={subText}>
                <p className={aboutEmileLg}>About Emile</p>
                Emile W. Arthur-Ricketts is a mastermind in Photography, Rap
                Music, and Christian Apparel. Born and raised in New York City,
                Emile discovered his passion for Rap and Hip-Hop and emerged as
                "DiE$eL-E", (Determined Individual Earning $ Every Lyric
                Experimentally). The acronym E.M.I.L.E has many meanings, but in short
                stands for: “Elohim's Miraculous Intervention Left Evidence”.
                <br />
                <br />
                Wearing many hats, as a father, entrepreneur, influencer,
                rapper, advocate, and so much more, Emile has also branded
                himself in Photography and Christian Apparel under Emile’s
                Creations LLC. In the field of photography, Emile intends to
                capture the perfect moment, especially for Rap and Hip-Hop
                concert scenes. In Christian apparel Emile wants to encourage,
                uplift and teach by capturing the Gospel in hindsight with
                catchphrases like "Speak Life".
                <br />
                <br />
                <UserLink cName={subLinkClass} linkTo={subLink}>
                  LEARN MORE
                </UserLink>
              </div>
            </Card.Body>
          </Wrapper>
        </Wrapper>
      </div>
    </>
  );
};
export default About;
