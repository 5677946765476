import React, { useLayoutEffect } from "react";
import { Card, Button } from "react-bootstrap";
import UserLink from "./ui/UserLink";

import PhotoStyle from "./Photos.module.css";
import useMediaQuery from "./UseMediaQuery";
import ConcertStyle from "./Galleries/Concert/ConcertStyle.module.css";
import Nav from "../Components/ui/Nav";

const ViewMorePhotos = () => {
  const cardWrapper = PhotoStyle.cardWrapper;
  const cardBody = PhotoStyle.cardBody;
  const title = PhotoStyle.title;
  const photoBtn = PhotoStyle.photoBtn;
  const photoBtnCelebrate = PhotoStyle.photoBtnCelebrate;
  const photoBtnFood = PhotoStyle.photoBtnFood;
  const photoBtnChurch = PhotoStyle.photoBtnChurch;
  const photoBtnSports = PhotoStyle.photoBtnSports;
  const photoBtnMaternity = PhotoStyle.photoBtnMaternity;
  const photoBtnPeople = PhotoStyle.photoBtnPeople;
  const photoBtnEthan = PhotoStyle.photoBtnEthan;
  const h3ConcertWrapper = PhotoStyle.h3ConcertWrapper;
  const h3MaternityWrapper = PhotoStyle.h3MaternityWrapper;
  const h3CelebrateWrapper = PhotoStyle.h3CelebrateWrapper;
  const backBtnView = ConcertStyle.backBtnView;
  const btnWrapper = ConcertStyle.btnWrapper;
  const body = PhotoStyle.body;

  const subLink = "/concert";
  const subLinkCelebrate = "/celebrate";
  const subLinkFood = "/food";
  const subLinkChurch = "/church";
  const subLinkSports = "/sports";
  const subLinkMaternity = "/maternity";
  const subLinkPeople = "/people";
  const subLinkEthan = "/ethan";
  
  // const subLink = "/tmp/emilescreations.com/concert";
  // const subLinkCelebrate = "/tmp/emilescreations.com/celebrate";
  // const subLinkFood = "/tmp/emilescreations.com/food";
  // const subLinkChurch = "/tmp/emilescreations.com/church";
  // const subLinkSports = "/tmp/emilescreations.com/sports";
  // const subLinkMaternity = "/tmp/emilescreations.com/maternity";
  // const subLinkPeople = "/tmp/emilescreations.com/people";
  // const subLinkEthan = "/tmp/emilescreations.com/ethan";

  const matchesSm = useMediaQuery("(max-width: 765px)");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className={body}>
        <Nav />
        {matchesSm ? (
          <Card className={cardWrapper}>
            <Card.Title className={title}>
              Emile's Creations Photography
            </Card.Title>
            <Card.Body className={cardBody}>
              <div className={btnWrapper}>
              <Button className={backBtnView} href="/tmp/emilescreations.com">
                Go Back Home
              </Button>
              </div>
              <div>
                <div className={h3MaternityWrapper}>
                  <h3>Maternity Gallery</h3>
                  <UserLink
                    cName={photoBtnMaternity}
                    linkTo={subLinkMaternity}
                  ></UserLink>
                </div>

                <div className={h3CelebrateWrapper}>
                  <h3>All Smiles Gallery</h3>
                  <UserLink
                    cName={photoBtnPeople}
                    linkTo={subLinkPeople}
                  ></UserLink>
                </div>

                <div className={h3CelebrateWrapper}>
                  <h3>Ethan Gallery</h3>
                  <UserLink
                    cName={photoBtnEthan}
                    linkTo={subLinkEthan}
                  ></UserLink>
                </div>

                <div className={h3ConcertWrapper}>
                  <h3>Concert Gallery</h3>
                  <UserLink cName={photoBtn} linkTo={subLink}></UserLink>
                </div>

                <div className={h3CelebrateWrapper}>
                  <h3>Celebrations Gallery</h3>
                  <UserLink
                    cName={photoBtnCelebrate}
                    linkTo={subLinkCelebrate}
                  ></UserLink>
                </div>

                <div className={h3CelebrateWrapper}>
                  <h3>Food Gallery</h3>
                  <UserLink
                    cName={photoBtnFood}
                    linkTo={subLinkFood}
                  ></UserLink>
                </div>

                <div className={h3CelebrateWrapper}>
                  <h3>Church Gallery</h3>
                  <UserLink
                    cName={photoBtnChurch}
                    linkTo={subLinkChurch}
                  ></UserLink>
                </div>

                <div className={h3CelebrateWrapper}>
                  <h3>Sports Gallery</h3>
                  <UserLink
                    cName={photoBtnSports}
                    linkTo={subLinkSports}
                  ></UserLink>
                </div>
              </div>
            </Card.Body>
          </Card>
        ) : (
          <Card className={cardWrapper}>
            <Card.Title className={title}>
              Emile Creations Photography
            </Card.Title>
            <Card.Body className={cardBody}>
              <div className={h3CelebrateWrapper}>
                <h3>Maternity Gallery</h3>
                <UserLink
                  cName={photoBtnMaternity}
                  linkTo={subLinkMaternity}
                ></UserLink>
              </div>
              <div className={h3CelebrateWrapper}>
                <h3>All Smiles Gallery</h3>
                <UserLink
                  cName={photoBtnPeople}
                  linkTo={subLinkPeople}
                ></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Ethan Gallery</h3>
                <UserLink
                  cName={photoBtnEthan}
                  linkTo={subLinkEthan}
                ></UserLink>
              </div>
              <div className={h3ConcertWrapper}>
                <h3>Concert Gallery</h3>
                <UserLink cName={photoBtn} linkTo={subLink}></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Celebrations Gallery</h3>
                <UserLink
                  cName={photoBtnCelebrate}
                  linkTo={subLinkCelebrate}
                ></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Food Gallery</h3>
                <UserLink cName={photoBtnFood} linkTo={subLinkFood}></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Church Gallery</h3>
                <UserLink
                  cName={photoBtnChurch}
                  linkTo={subLinkChurch}
                ></UserLink>
              </div>

              <div className={h3CelebrateWrapper}>
                <h3>Sports Gallery</h3>
                <UserLink
                  cName={photoBtnSports}
                  linkTo={subLinkSports}
                ></UserLink>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};
export default ViewMorePhotos;
